
import { reactive, defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { updatePassword } from "@/api/mine";
import { logout } from "@/api/web";
import { Toast } from "vant";

export default defineComponent({
  setup() {
    const state = reactive({
      oldPassword: "",
      newPassword: "",
      againPassword: "",
    });
    const params = {
      globalAccount: "",
      nameCn: "",
      newPassword: "",
      oldPassword: "",
      roleId: "",
      userId: "",
      passwordLevel: "",
    };

    const loginToast = () => {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "您已修改密码,3秒后将自动退出",
      });
      let second = 3;
      const timer = setInterval(() => {
        second--;
        if (second) {
          toast.message = `您已修改密码,${second}秒后将自动退出`;
        } else {
          clearInterval(timer);
          Toast.clear();
          router.replace("login");
        }
      }, 1000);
    };

    const router = useRouter();
    const store = useStore();
    const onSubmit = async () => {
      params.newPassword = state.newPassword;
      params.oldPassword = state.oldPassword;
      params.passwordLevel = "2"; //后端让先写2
      const res = await updatePassword({ ...params });
      if ((res as any).resp_code === 0) {
        loginToast();
        logout();
        store.commit('setToken', null);
      } else {
        Toast({
          type: "fail",
          message: (res as any).resp_msg,
        });
      }
    };

    const validFailed = (err: any) => {
      Toast({
        type: "fail",
        message: err.errors[0].message,
      });
    };

    const validPassword = (value: string) => {
      return !!value.match(/^(?=.*[A-Za-z])||(?=.*\d)[A-Za-z\d]{6,14}$/);
    };

    const validAgainPassword = (value: string) => {
      return value === state.newPassword;
    };

    const route = useRoute();
    onMounted(() => {
      params.nameCn = (route.params as any).name;
      params.globalAccount = (route.params as any).phone;
      params.userId = (route.params as any).userId;
      params.roleId = (route.params as any).roleId;
    });

    let oldBtnName = ref("eye-o");
    let newBtnName = ref("eye-o");
    let againBtnName = ref("eye-o");
    let oldPasswordType = ref("password");
    let newPasswordType = ref("password");
    let againPasswordType = ref("password");

    const changeOldBtnType = () => {
      if (oldBtnName.value === "eye-o") {
        oldBtnName.value = "closed-eye";
        oldPasswordType.value = "";
      } else if (oldBtnName.value === "closed-eye") {
        oldBtnName.value = "eye-o";
        oldPasswordType.value = "password";
      }
    };

    const changeNewBtnType = () => {
      if (newBtnName.value === "eye-o") {
        newBtnName.value = "closed-eye";
        newPasswordType.value = "";
      } else if (newBtnName.value === "closed-eye") {
        newBtnName.value = "eye-o";
        newPasswordType.value = "password";
      }
    };
    const changeAgainBtnType = () => {
      if (againBtnName.value === "eye-o") {
        againBtnName.value = "closed-eye";
        againPasswordType.value = "";
      } else if (againBtnName.value === "closed-eye") {
        againBtnName.value = "eye-o";
        againPasswordType.value = "password";
      }
    };

    return {
      state,
      onSubmit,
      validFailed,
      validPassword,
      validAgainPassword,
      oldBtnName,
      newBtnName,
      againBtnName,
      oldPasswordType,
      newPasswordType,
      againPasswordType,
      changeOldBtnType,
      changeNewBtnType,
      changeAgainBtnType,
    };
  },
});
